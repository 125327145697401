.stars {
  display: flex;
  justify-content: center;
  gap: 0;
}

.star {
  margin-left: 0px;
  position: relative;
  cursor: pointer;
}

.button {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  padding: 1px;
  margin: 1px;
}

.button:hover {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
}

y.button:active {
  outline: none;
  border: none;
}

.button:focus {
  outline: 0;
}